.app-footer {
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem;
  text-align: center;
  font-size: 0.8rem;
  flex-shrink: 0; /* Prevents the footer from shrinking */
}

.app-footer p {
  margin: 0.2rem 0;
  color: white;
}

.app-footer a {
  color: var(--secondary-color);
  text-decoration: underline;
}

.app-footer a:hover {
  text-decoration: none;
}