.calendar-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  min-height: 0; /* This is important for flexbox to work properly */
}

.calendar-wrapper .rbc-calendar {
  flex: 1;
  min-height: 0; /* This is important for flexbox to work properly */
}

/* Remove the fixed height style */
.calendar-wrapper .rbc-calendar {
  height: auto !important;
}

.dark-calendar {
  background-color: #1a202c;
  color: #e2e8f0;
}

.dark-calendar .rbc-off-range-bg {
  background-color: #2d3748;
}

.dark-calendar .rbc-today {
  background-color: rgba(66, 153, 225, 0.2);
}

.dark-calendar .rbc-event {
  background-color: #4a5568;
}

.dark-calendar .rbc-button-link {
  color: #e2e8f0;
}

.dark-calendar .rbc-header {
  background-color: #2d3748;
  color: #e2e8f0;
  border-bottom: 1px solid #4a5568;
}

.dark-calendar .rbc-month-view,
.dark-calendar .rbc-time-view,
.dark-calendar .rbc-agenda-view {
  border: 1px solid #4a5568;
}

.dark-calendar .rbc-day-bg+.rbc-day-bg,
.dark-calendar .rbc-month-row+.rbc-month-row {
  border-color: #4a5568;
}

.dark-calendar .rbc-time-view {
  border: 1px solid #4a5568;
}

.dark-calendar .rbc-time-header {
  border-bottom: 1px solid #4a5568;
}

.dark-calendar .rbc-time-header-content {
  border-left: 1px solid #4a5568;
}

.dark-calendar .rbc-time-header-cell {
  border-bottom: 1px solid #4a5568;
}

.dark-calendar .rbc-time-slot {
  border-top: 1px solid rgba(74, 85, 104, 0.3);
}

.dark-calendar .rbc-time-content {
  border-top: 2px solid #4a5568;
}

.dark-calendar .rbc-time-content>*+*>* {
  border-left: 1px solid #4a5568;
}

.dark-calendar .rbc-day-slot .rbc-time-slot {
  border-top: 1px solid rgba(74, 85, 104, 0.3);
}

.dark-calendar .rbc-timeslot-group {
  border-bottom: 1px solid rgba(74, 85, 104, 0.3);
}

.dark-calendar .rbc-time-gutter,
.dark-calendar .rbc-time-header-gutter {
  background-color: #2d3748;
}

.dark-calendar .rbc-allday-cell {
  border-bottom: 1px solid #4a5568;
}

@media (max-width: 768px) {
  .calendar-wrapper {
    padding: 0.25rem;
  }
}