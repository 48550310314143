.app-header {
  background-color: var(--primary-color);
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.header-title {
  display: flex;
  flex-direction: column;
}

.app-header h1 {
  margin: 0;
  font-size: 1.5rem;
  color: white;
}

.header-slogan {
  font-size: 0.8rem;
  margin: 0.2rem 0 0 0;
  color: var(--secondary-color);
  font-style: italic;
}

.header-slogan a {
  color: #00a5dd;
  text-decoration: underline;
}

.header-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.region-select {
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  background-color: white;
  color: var(--text-light);
}

.dark-mode-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .app-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-controls {
    margin-top: 1rem;
  }
}