.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 12px;
  padding: 0;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.modal.dark {
  background-color: #2d3748;
  color: #e2e8f0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-banner {
  height: 200px;
  background-size: cover;
  background-position: top center;
  /* Changed from center to top center */
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  /* Ensure the gradient doesn't spill outside rounded corners */
}

.banner-gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.5) 70%,
      rgba(255, 255, 255, 1) 100%);
}

/* For dark mode */
.modal.dark .banner-gradient {
  background: linear-gradient(to bottom,
      rgba(45, 55, 72, 0) 0%,
      rgba(45, 55, 72, 0.5) 50%,
      rgba(45, 55, 72, 1) 100%);
}

.modal-body {
  padding: 24px;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #2d3748;
}

.modal.dark .modal-title {
  color: #e2e8f0;
}

.event-description {
  font-size: 16px;
  margin-bottom: 24px;
  line-height: 1.5;
}

.event-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.event-detail {
  display: flex;
  flex-direction: column;
}

.event-detail strong {
  font-size: 14px;
  text-transform: uppercase;
  color: #718096;
  margin-bottom: 4px;
}

.modal.dark .event-detail strong {
  color: #a0aec0;
}

.airport-list {
  margin-bottom: 24px;
}

.airport-list strong {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  color: #718096;
  margin-bottom: 8px;
}

.modal.dark .airport-list strong {
  color: #a0aec0;
}

.airport-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.airport-badge {
  background-color: #edf2f7;
  color: #2d3748;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.modal.dark .airport-badge {
  background-color: #4a5568;
  color: #e2e8f0;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vatsim-link {
  display: inline-flex;
  align-items: center;
  background-color: #3182ce;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.3s;
}

.vatsim-link:hover {
  background-color: #2c5282;
}

.external-link-icon {
  margin-left: 8px;
}

.close-button {
  background-color: #e2e8f0;
  color: #2d3748;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #cbd5e0;
}

.modal.dark .close-button {
  background-color: #4a5568;
  color: #e2e8f0;
}

.modal.dark .close-button:hover {
  background-color: #718096;
}