.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
    flex-wrap: wrap;
  }
  
  .toolbar-nav,
  .toolbar-views {
    display: flex;
    align-items: center;
  }
  
  .nav-button,
  .view-button,
  .today-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .nav-button:hover,
  .view-button:hover,
  .today-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .dark .nav-button:hover,
  .dark .view-button:hover,
  .dark .today-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .view-button.active {
    background-color: var(--primary-color);
    color: white;
  }
  
  .dark .view-button.active {
    background-color: var(--primary-color-dark);
  }
  
  .toolbar-label {
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .toolbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .toolbar-nav,
    .toolbar-views {
      margin-bottom: 0.5rem;
    }
  }