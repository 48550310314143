:root {
  --primary-color: #040d21;
  --secondary-color: #2ecc71;
  --background-light: #f5f7fa;
  --background-dark: #121212;
  --text-light: #333;
  --text-dark: #ecf0f1;
}

body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-light);
  color: var(--text-light);
  transition: background-color 0.3s, color 0.3s;
}

body.dark {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

/* Ensure that the app container takes up full height */
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Additional styles to ensure content is visible */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* This ensures the container takes at least the full viewport height */
}

.app-container.dark {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

@media (max-width: 768px) {
  .app-container {
    padding: 0;
  }
}